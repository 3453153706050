<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="400px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Enviar e-mail certificado
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4">
          <v-row>
            <v-col>
              Tem certeza que deseja enviar o e-mail com o certificado para todos os inscritos com presença confirmada?
            </v-col>
          </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          outlined
          :loading="loading"
          @click="send"
        >
          <v-icon
            left
            v-text="'mdi-send'"
          />
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import enrolledApi from '@/api/enrolled'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      eventId: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        loading: false,
        file: null,
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    methods: {

      async send () {
        try {
          this.loading = true

          await enrolledApi.certificateSendEmailToAll(this.eventId)

          this.show = false
          this.$snackbar.show({ color: 'success', message: 'E-mails enviados com sucesso!' })
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading =false
        }
      },

    },

  }
</script>
